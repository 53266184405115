//YELLOWS
$color-shell-yellow: #fbce07;
$color-shell-yellow--tint-80: #fcd839;
$color-shell-yellow--tint-60: #fde26a;
$color-shell-yellow--tint-40: #fdeb9c;
$color-shell-yellow--tint-20: #fef5cd;
$color-shell-yellow--tint-10: #fbf3d0;

//REDS
$color-shell-red: #dd1d21;
$color-shell-red--tint-80: #e44a4d;
$color-shell-red--tint-60: #eb777a;
$color-shell-red--tint-40: #eb777a;
$color-shell-red--tint-20: #f8d2d3;
$color-shell-red-v2: #bd0609;

//WHITE
$color-white: #fff;

//BLACK
$color-black: #000;

//GRAYS
$color-gray--very-dark: #404040;
$color-gray--dark: #595959;
$color-gray--mild: #717171;
$color-gray--light: #a6a6a6;
$color-gray--pale: #d9d9d9;
$color-gray--paler: #e2e2e2;
$color-gray--palest: #e8e8e8;
$color-gray--quite-pale: #f0f0f0;
$color-gray--very-pale: #f7f7f7;
$color-gray--medium: #7f7f7f;
$color-gray--pastry: #f4f4f4;
$color-gray--neutro: #e6e6e6;
$color-gray--neutro-2: #eee;
$color-gray--neutro-3: #ccc;

//PETROLEUM BLUE
$color-petroleum-blue: #011638;

//DARK BLUE
$color-dark-blue: #003c88;
$color-dark-blue--darker: #1a2d4b;
$color-dark-blue--tint-1: #3363a0;
$color-dark-blue--tint-2: #668ab8;
$color-dark-blue--tint-3: #99b1cf;
$color-dark-blue--tint-4: #ccd8e7;

//MID BLUE
$color-mid-blue: #009eb4;
$color-mid-blue--darker: #2a7a8f; // Exception made for accessibility purposes
$color-mid-blue--darker-2: #1e5868;
$color-mid-blue--tint-1: #33b1c3;
$color-mid-blue--tint-2: #66c5d2;
$color-mid-blue--tint-3: #99d8e1;
$color-mid-blue--tint-4: #ccecf0;

//LIGHT BLUE
$color-light-blue: #89cfdc;
$color-light-blue--tint-1: #a1d9e3;
$color-light-blue--tint-2: #b8e2ea;
$color-light-blue--tint-3: #d0ecf1;
$color-light-blue--tint-4: #e7f5f8;

//DARK GREEN
$color-dark-green: #008443;
$color-dark-green--tint-1: #339d69;
$color-dark-green--tint-2: #66b58e;
$color-dark-green--tint-3: #99ceb4;
$color-dark-green--tint-4: #cce6d9;
$color-dark-green--tint-5: #309a66;

//LIME GREEN
$color-lime-green: #7fb320;

//LIGHT GREEN
$color-light-green: #bed50f;
$color-light-green--tint-1: #cbdd3f;
$color-light-green--tint-2: #d8e66f;
$color-light-green--tint-3: #e5ee9f;
$color-light-green--tint-4: #f2f7cf;

//MARINE BLUE
$color-marine-blue: #4d76ab;
$color-marine-blue--tint-1: #425f9c;

//ORANGE
$color-orange: #eb8705;
$color-orange--tint-1: #ef9f37;
$color-orange--tint-2: #f3b769;
$color-orange--tint-3: #f7cf9b;
$color-orange--tint-4: #fbe7cd;
$color-shell-orange: #fa6400;

//BROWN
$color-brown: #743410;
$color-brown--tint-1: #905d40;
$color-brown--tint-2: #ac8570;
$color-brown--tint-3: #c7ae9f;
$color-brown--tint-4: #e3d6cf;

//SAND
$color-sand: #ffeac2;
$color-sand--tint-1: #ffeece;
$color-sand--tint-2: #fff2da;
$color-sand--tint-3: #fff7e7;
$color-sand--tint-4: #fffbf3;

//PURPLE
$color-purple: #641964;
$color-purple--tint-1: #834783;
$color-purple--tint-2: #a275a2;
$color-purple--tint-3: #c1a3c1;
$color-purple--tint-4: #e0d1e0;

//LILAC
$color-lilac: #ba95be;
$color-lilac--tint-1: #c8aacb;
$color-lilac--tint-2: #d6bfd8;
$color-lilac--tint-3: #e3d5e5;
$color-lilac--tint-4: #f1eaf2;

//TORQUISE
$color-turquise-blue: #258197;
$color-turquise-blue-light: #009eb4;

$color-body-background: $color-white;
$color-error: $color-shell-red;
$color-success: $color-dark-green;

$pie-chart-section-colors: (
  a: $color-shell-yellow,
  b: $color-light-blue,
  c: $color-mid-blue,
  d: $color-light-green,
  e: $color-lilac,
);

$pie-chart-section-colors-finance: (
  a: $color-shell-red,
  b: $color-orange,
  c: $color-shell-yellow,
  d: $color-light-green,
);

$pie-chart-section-colors-charges: (
  a: $color-lilac,
  b: $color-purple,
  c: $color-orange,
  d: $color-gray--light,
);

$pie-chart-section-colors-productTypes: (
  a: $color-dark-blue,
  b: $color-shell-red,
  c: $color-shell-yellow,
  d: $color-mid-blue,
  e: $color-gray--light,
);

$pie-chart-section-colors-restriction-balances: (
  green: $color-light-green,
  orange: $color-orange,
  red: $color-shell-red,
);

$bar-chart-colors-1: (
  a: $color-gray--dark,
  b: $color-shell-red,
  c: $color-shell-yellow,
  d: $color-mid-blue,
  e: $color-gray--pale,
);

$bar-chart-colors-2: (
  a: $color-light-green,
  b: $color-dark-green,
  c: $color-light-blue,
  d: $color-mid-blue,
  e: $color-dark-blue,
);

$bar-chart-colors-3: (
  a: $color-light-blue,
  b: $color-mid-blue,
  c: $color-light-green,
  d: $color-lilac,
  e: $color-gray--pale,
);

$bar-chart-colors-4: (
  a: $color-dark-blue,
  b: $color-shell-red,
  c: $color-shell-yellow,
  d: $color-mid-blue,
  e: $color-gray--pale,
);

$color-hightlight-1: #fbce07;
$color-hightlight-2: #dd1d21;
$color-hightlight-3: #641964;

$neutral-100: #fff;
$neutral-200: #f7f7f7;
$neutral-250: #f5f5f5;
$neutral-300: #eaeaea;
$neutral-350: #e6e6e6;
$neutral-400: #a6a6a6;
$neutral-440: #7f7f7f;
$neutral-445: #757575;
$neutral-450: #595959;
$neutral-500: #404040;
$neutral-600: #303030;
$neutral-700: #212121;
$neutral-800: #141414;
$neutral-900: #000;

$info-success: #008443;
$info-success-light: #e7f3ed;
$info-danger: $color-hightlight-2;

$hover-highlight-1: #d1ac06;
$hover-highlight-2: #b5181b;
$hover-highlight-3: #3b0f3b;
$hover-success: #005c2f;
$hover-light: #ffffff29;
$hover-dark-mode-1: #727782;
$hover-dark-mode-2: #7f838f;

$dark-mode-primary: #141c2f;
$dark-mode-secondary: #21293c;
$dark-mode-tertiary: #293145;
$dark-mode-neutral: #9398a7;

$disabled-highlight-1: #fef7d7;
$disabled-highlight-2: #f9dbdb;
$disabled-highlight-3: #e6dae6;

$link: #009eb4;
