@import 'colors.scss';
@import 'fonts.scss';
@import 'queries.scss';

@import '../assets/fontawesome/scss/fontawesome.scss';
@import '../assets/fontawesome/scss/solid.scss';
@import '../assets/fontawesome/scss/regular.scss';
@import '../assets/fontawesome/scss/light.scss';

html,
body {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  color: $neutral-900;
  background-color: $neutral-200;

  .dark-theme {
    color: $neutral-100;
    background-color: $dark-mode-primary;
  }
}

html {
  background-color: transparent;
}
