@font-face {
  font-family: ShellLight;
  src: url('~src/assets/fonts/Shell-Light.woff') format('woff');
  src: url('~src/assets/fonts/Shell-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: ShellLightItalic;
  src: url('~src/assets/fonts/Shell-LightItalic.woff') format('woff');
  src: url('~src/assets/fonts/Shell-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: ShellBook;
  src: url('~src/assets/fonts/Shell-Book.woff') format('woff');
  src: url('~src/assets/fonts/Shell-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: ShellBookItalic;
  src: url('~src/assets/fonts/Shell-BookItalic.woff') format('woff');
  src: url('~src/assets/fonts/Shell-BookItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: ShellMedium;
  src: url('~src/assets/fonts/Shell-Medium.woff') format('woff');
  src: url('~src/assets/fonts/Shell-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: ShellMediumItalic;
  src: url('~src/assets/fonts/Shell-MediumItalic.woff') format('woff');
  src: url('~src/assets/fonts/Shell-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: ShellBold;
  src: url('~src/assets/fonts/Shell-Bold.woff') format('woff');
  src: url('~src/assets/fonts/Shell-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: ShellHeavy;
  src: url('~src/assets/fonts/Shell-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: InterThin;
  src: url('~src/assets/fonts/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: InterExtraLight;
  src: url('~src/assets/fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: InterLight;
  src: url('~src/assets/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: InterRegular;
  src: url('~src/assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: InterMedium;
  src: url('~src/assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: InterSemiBold;
  src: url('~src/assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: InterBold;
  src: url('~src/assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: InterExtraBold;
  src: url('~src/assets/fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: InterBlack;
  src: url('~src/assets/fonts/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
